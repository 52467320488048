<template>


    <layout-admin-dashboard-view
    :showToolbar="false"
    :backRoute="{name: 'entity-list', params: {entityType:entityType}}">
        <template #title>
            {{translateTitleCase('entity.crud.createEntityTitle')}} <strong>{{ucFirst(entityType)}}</strong>
        </template>


        <template #default>
           <crud-create
                   :formPath="'entity/'+entityType+'/create'"
                   :formAction="'entity/'+entityType"
                   :successRedirect="{'name': 'entity-list', params: {entityType:entityType}}"
                :entityType="entityType">

           </crud-create>

        </template>
    </layout-admin-dashboard-view>



</template>

<script>
    import CrudCreate from '@/client/components/crud/Create.vue';


    export default {
        props: {
            entityType: {
                type: String,
                default: 'default'
            }
        },
        name: 'entity-create',
        components: {CrudCreate},
        data: function () {
            return {
                entity: {}
            };
        },
        computed: {
            formPath () {
                return 'entity/'+this.entityType+'/create'
            },
            formAction () {
                return 'entity/'+this.entityType
            }
        },
        methods: {

        }

    }
</script>

<style scoped lang="scss">

</style>
